import $ from 'jquery';

$(document).ready(function() {
		
	$(window).scroll(function() {
		var sticky = $('header'),
		scroll = $(window).scrollTop();
	
		if (scroll >= 100) { 
			sticky.addClass('fixed');
		} else {
			sticky.removeClass('fixed');

		}
	});

	//HAMBURGER
	
	$("#menu-toggle").click(function(e) {
	    e.preventDefault();
	    $("header").toggleClass("toggled");
	    $("#sidebar-wrapper").toggleClass("toggled");
	});
	
	$("#sidebar-wrapper .close").click(function(e) {
	    e.preventDefault();
	    $("header").removeClass("toggled");
		$("#sidebar-wrapper").toggleClass("toggled");
	});

	$('.sidebar-nav .dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').addClass('dropdown-toggle');

	$('.menu-item').on("click", function(e) {
		$('.dropdown-submenu.show').removeClass('show').find('.dropdown-menu, .dropdown-toggle').removeClass('show');
		$('.dropdown-submenu > a.dropdown-toggle[aria-expanded="true"]').attr('aria-expanded', 'false');
	});

	$('.dropdown-submenu > a.dropdown-toggle').on("click", function(e) {
		e.preventDefault();

		var parentLi = $(this).parent();
		var dropdownLink = $(this);

		if (parentLi.hasClass('show')) {
			parentLi.removeClass('show');
			parentLi.find('.dropdown-menu, .dropdown-toggle').removeClass('show');
			dropdownLink.attr('aria-expanded', 'false');
		} else {
			// Fermez tous les autres sous-sous-menus ouverts du même parent
			var siblingLinks = parentLi.siblings('.dropdown-submenu.show').removeClass('show').find('.dropdown-menu, .dropdown-toggle').removeClass('show');
			siblingLinks.parent().find('> a.dropdown-toggle[aria-expanded="true"]').attr('aria-expanded', 'false');

			parentLi.addClass('show');
			parentLi.find('.dropdown-menu, .dropdown-toggle').addClass('show');
			dropdownLink.attr('aria-expanded', 'true');
		}

		e.stopPropagation();
	});


	let timeoutID = null;

	$('#menu-navigation a').click((event) => {
		event.preventDefault();
		const navbar = $('.navbar');
		const menu = event.target.attributes.href.value.replace('#', '');

		if (timeoutID !== null)
			clearTimeout(timeoutID);

		let submenu = $('.submenu.submenu__' + menu).get(0);

		if (submenu && submenu.style && submenu.style.display === 'block') {
			navbar.removeClass('active');
			$(submenu).hide();
		} else {
			$('.submenu:not(.submenu__' + menu + ')').hide();
			navbar.addClass('active');
			$(submenu).show();
		}
	});

	$('#menu-navigation a').blur((event) => {
		timeoutID = setTimeout(() => {
			$('.submenu').hide();
			$('.navbar').removeClass('active');
			timeoutID = null;
		}, 1000);
	});

	var fields = [ ".searchbox input" ];
	$(fields).each(function() {
		$(this).on('focus', function() {
			$(this).parent().parent('.gfield').addClass('active');
		});

		$(this).on('blur', function() {
			if ($(this).val().length == 0) {
				$(this).parent().parent('.gfield').removeClass('active');
			}
		});
		if ($(this).val() != '') $(this).parent('.css').addClass('active');

	});

	$(".search__desktop i").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeIn(300);
	});

	$(".searchbox i").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeOut(300);
	});


	$(document).on('click', '.submenu__column__content a', function (e) {
		var h = $(this).attr('href');
		if ($(h).length > 0) { // Vérifie si l'ancre existe
			e.preventDefault(); // Empêche le comportement par défaut du lien
			$('body,html').animate({
				scrollTop: $(h).offset().top - 120
			}, 800);
		}
	});

});