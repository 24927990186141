import $ from 'jquery';

$(document).ready(function() {

    $('.matchHeight').matchHeight();

    $('.toggle-btn').click(function() {
        $(this).parent().toggleClass('active').siblings().removeClass('active');
        //$(this).parent().find('.single_switcher__active').toggleClass('active').siblings().removeClass('active');
    });

    $(function() {
        AOS.init();
    });

});